import React from 'react'
import { AppLocale } from '@hrk/types'
import { LayoutWrapper } from '../components/layout/layoutWrapper'

const YXJjb25zaXN3YXNoZXJl: React.FC = () => {
  const currentLocale: AppLocale = 'en'

  return (
    <>
      <LayoutWrapper
        currentLocale={currentLocale}
        otherLocales={[{ locale: 'de', slug: '/YXJjb25zaXN3YXNoZXJl' }]}
        hideFooterButtons={true}
        hideFooterLinks={false}
      >
        <br />
        <br />
      </LayoutWrapper>
    </>
  )
}

export default YXJjb25zaXN3YXNoZXJl
